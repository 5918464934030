<template>
    <div class="">
      <img style="width:100%;height:auto;" src="/static/img/xuqiuzixun.png" />
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
export default {
    setup(){
        const {proxy} = getCurrentInstance();
        const industries = ref([]);
        const loading = ref(false)

       
        console.log('aa')

        return  {industries, loading}
    },
    methods:{
        gotoDetail(index){
            if(index == 0){
                this.$router.push({name: 'Industry', path: 'industry'})
            }
           
        }
    },
}
</script>

<style lang="less" scoped>
.industry{
    padding-top: 30px;
    margin-bottom: 112px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .industry-list{

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        

        .industry-item{
            background: #c3c3c3;
            width: 589px;
            height: 185px;
            background-size: 589px 185px;
            background-position: 0 0;
            text-align: center;
            position: relative;
            cursor: pointer; 
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        .title{
            text-align: center;
            font-family: SourceHanSansCN-Bold;
            font-size: 36px;
            line-height: 36px;
            color: #FFFFFF;
            text-shadow: 0 2px 3px rgba(0,0,0,0.50);
        }
        .sub-title{
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            line-height: 16px;
            margin-top: 6px;
            color: #FFFFFF;
            text-shadow: 0 2px 3px rgba(0,0,0,0.50);
        }
    }
}
</style>


