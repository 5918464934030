<template>
    <div class="content industry">
        <div class="industry1" style="background-image: url('./static/img/skyscraper.png');background-repeat: no-repeat; background-size: 100% 100%;">
            <div class="title">建立专区</div>
            <div class="entry" @click="gotoRegister">
                立即注册
            </div>
        </div>  

        <div class="industry-list" v-loading="loading">
            <template v-for="(item, index) in industries" :key="item.id">
                <div class="industry-item" @click="gotoDetail(item.id)" :style="{'background-image': 'url('+item.imageUrl+')'}">
                    <div class="title">{{item.zoneName}}</div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
import { ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import {useRoute} from 'vue-router'

export default {
    setup(){
        const {proxy} = getCurrentInstance();
        const industries = ref([]);
        const loading = ref(false)

        const route = useRoute();

        const zoneClass = ref('');
        if(route.query && route.query.zoneClass){
            zoneClass.value = route.query.zoneClass;
        }

        function getIndustries(){
            loading.value = true;
            const url = '/gdsti2/web/a/webZone/list?noPage=1&zoneClass=' + zoneClass.value;
            proxy.$http.post(url).then(res=>{
                loading.value = false
                if(res.data.state == 0){

                    const result = res.data.result;
                    for(let i = 0 ; i < result.length; i++){
                        if(result[i].imgs){
                            result[i].imageUrl = proxy.$getImage(result[i].imgs.split(",")[0]);
                        }
                    }
                    industries.value = result;
                    // console.log(industries.value)
                }
            }).catch(err=>{
                loading.value = false
            })        
        }

        onMounted(()=>{
            getIndustries()
        })

        return  {industries, loading}
    },
    methods:{
        gotoRegister(){
          ElMessageBox.alert('请联系网站管理员进行专区建设', '提醒', {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: '确定',
            callback: (action) => {

            },
          })
        },
        gotoDetail(itemId){
            this.$router.push({name: 'IndustryDetail', path: 'industryDetail', query: {id: itemId}})
        }
    },
}
</script>

<style lang="less" scoped>
.industry{
    padding-top: 30px;
    margin-bottom: 112px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .industry1{
        width: 283px;
        height: 466px;

        .title{
            font-family: SourceHanSansCN-Bold;
            font-size: 44px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            line-height: 50px;
            margin-top: 150px;
        }
        .entry{
            font-family: SourceHanSansCN-Regular;
            font-size: 20px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: center;
            background: rgba(255,255,255,0.25);
            border: 1px solid #FFFFFF;
            cursor: pointer;
            margin-top: 90px;
            width: 160px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            
        }
        .entry:hover{
            background: rgba(255,255,255,0.35);
        }
    }

    .industry-list{

        flex: 1;
        display: flex;
        flex-direction: row;
        margin-left: 22px;
        justify-content: space-between;
        flex-wrap: wrap;
        

        .industry-item{
            background: #c3c3c3;
            width: 435px;
            height: 225px;
            background-size: 435px 225px;
            background-position: 0 0;
            text-align: center;
            position: relative;
            cursor: pointer; 
            margin-bottom: 15px;
        }
        .title{
            
            position: absolute;
            text-align: center;
            width: 100%;
            bottom: 15px;
            font-family: SourceHanSansCN-Medium;
            font-size: 22px;
            color: #FFFFFF;
        }
    }
}
</style>


